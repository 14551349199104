let backendAddress;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  backendAddress = 'http://localhost:3000/';
} else {
  backendAddress = 'https://backoffice.finpartner.pt/apiv2/';
}
export { backendAddress };

export const documentTags = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: 'Pagamentos', value: 'payments' },
  { label: 'Faturas', value: 'invoices' },
  { label: 'Compras', value: 'shopping' },
  { label: 'Extratos', value: 'movements' }
];
